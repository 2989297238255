<template>
    <div>
        <div class="container-fluid">
            <b-col class=" card shadow border border-white w-50 p-2 m-5 bg-white" style="margin-left: 25% !important;">
                <div class="text-center m-2 p-2">
                    <h3>public exam Dashboard</h3>
                    <span>
                        <b-link @click="logOutPublicExam" class="text-danger">Logout</b-link>
                    </span>
                </div>
            </b-col>
            <b-col class=" card shadow border border-white w-50 p-2 m-5 bg-white" style="margin-left: 25% !important;">
                <table class="table table-striped table-bordered" v-if="publicexams.length != 0">
                    <thead style="font-size: 14px; font-weight: 600; text-align: center" class="thead-dark">
                        <tr>
                            <th>Activity Name</th>
                            <th>Type</th>
                            <th>Course Code</th>
                            <th>Created Date</th>
                            <th>Start</th>
                            <th>End</th>
                        </tr>
                    </thead>
                    <!--********Public Exam Table *****************-->
                    <tbody>
                        <tr v-if="publicexams.length == 0">
                            <td colspan="100" class="text-danger text-center font-weight-bolder">
                                No Activities!
                            </td>
                        </tr>
                        <tr style="font-size: 14px; font-weight: 600; text-align: center"
                            v-for="(exam, examIndex) in publicexams" :key="examIndex" v-else>
                            <td class="text-left">
                                <b-tooltip :target="'exam' + examIndex" triggers="hover" placement="topright">
                                    <p style="font-size: 14px">{{ exam.summary }}</p>
                                </b-tooltip>
                                <span :class="[exam.deleted_at ? 'text-danger' : '']" :id="'exam' + examIndex">{{
                                    exam.name
                                }}</span>
                                <br>
                                <div v-if="(exam.deleted_at != null)">
                                    <vue-countdown :time="timeToDeleteIn(exam.deleted_at)"
                                        v-slot="{ hours, minutes, seconds }">
                                        <b-badge variant="danger" style="white-space: nowrap; margin-right: 2px;">Delete
                                            within {{
                                                hours
                                            }}:{{ minutes }}:{{ seconds }}
                                        </b-badge>
                                        <b-link @click="CancelDeletion(exam.id)">
                                            <b-badge variant="success">Cancel</b-badge>
                                        </b-link>
                                    </vue-countdown>
                                </div>
                                <b-button variant="primary" v-if="today >= exam.startAt1 && today < exam.endAt1" size="sm"
                                    @click="startExam(exam.id)">
                                    Start Exam</b-button>
                                &nbsp; <b-button variant="primary" v-if="today >exam.endAt1" size="sm"
                                    @click="viewScore(exam.id)">View Score</b-button>
                            </td>
                            <td class="align-middle">
                                <span class="bg-info p-1 rounded text-white m-0">
                                    Online
                                </span>
                            </td>
                            <td class="align-middle">
                                <span class="bg-info p-1 rounded text-white">
                                    Non Course
                                </span>
                            </td>
                            <td class="align-middle">
                                <span v-if="exam.createdAt1.isValid()">
                                    {{ exam.createdAt1.format("DD/MM/YYYY") }}</span>
                                <span v-else>Date not updated</span>
                            </td>
                            <td class="align-middle">
                                <span v-if="exam.startAt1.isValid()">
                                    {{ exam.startAt1.format("DD/MM/YYYY") }}
                                    <br />
                                    {{ exam.startAt1.format("hh:mm a") }}
                                </span>
                                <span v-else>Date not updated</span>
                            </td>
                            <td class="align-middle">
                                <span v-if="exam.endAt1.isValid()">
                                    {{ exam.endAt1.format("DD/MM/YYYY") }}
                                    <br />
                                    {{ exam.endAt1.format("hh:mm a") }}

                                </span>
                                <span v-else>Date not updated</span>
                            </td>
                        </tr>
                    </tbody>
                    <!--************end public Exam Table**************-->
                </table>
            </b-col>
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    props: [],
    async created() {
        this.getPublicExams();
    },
    components: {},
    data() {
        return {
            publicexams: [],
            today: moment(),
        }
    },
    computed: {},
    methods: {
        startExam(activityId) {
            let type = 0;
            let route = this.$router.resolve({
                path: `/publicexamanswers/${activityId}/${type}`
            });
            window.open(route.href, '_blank');
        },
        viewScore(activityId) {
            let type = 0;
            let route = this.$router.resolve({
                path: `/studentScore/${activityId}/${type}`
            });
            window.open(route.href, '_blank');
        },
        canStartExam(exam) {
            if (this.today < exam.startAt1 || this.today > exam.endAt1) {
                return true;
            }
        },
        getPublicExams() {
            this.fetchData = true;
            const url = this.$store.getters.getAPIKey.mainAPI +
                `/publicexampublicuser/exams`;
            this.$axios
                .get(url)
                .then((response) => {
                    let publicexamsList = response.data;
                    publicexamsList.forEach((publicexam) => {
                        this.publicexams.push({
                            id: publicexam.id,
                            name: publicexam.name,
                            description: publicexam.summary,
                            starts: publicexam.starts_at,
                            startAt1: moment(
                                moment.utc(publicexam.starts_at, "YYYY-MM-DD HH:mm:ss").toDate()
                            ),
                            ends: publicexam.end_at,
                            endAt1: moment(
                                moment.utc(publicexam.end_at, "YYYY-MM-DD HH:mm:ss").toDate()
                            ),
                            createdAt1: moment(new Date(publicexam.created_at)),
                            maximum_mark: publicexam.maximum_mark,
                            hidden: publicexam.hidden,
                        });
                    });
                    this.fetchData = false;
                    response;
                })
                .catch((error) => {
                    error;
                });
        },
        logOutPublicExam() {
            localStorage.removeItem("publicexamAuthToken");
            localStorage.removeItem("publicUrlUser");
            localStorage.removeItem("token");
            localStorage.removeItem("userPermissions");
            localStorage.removeItem("auditorAuthToken");
            this.$router.push('/publicexam/login');
        },
    },
};
</script>

<style ></style>
